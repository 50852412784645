<template>
  <v-card class="rounded-lg shadow-regular">
    <v-card-text class="py-5 px-7">
      <p class="card-title mb-0 black--text">Alerts &amp; Badges</p>
      <v-container>
        <!-- Alerts Section -->
        <v-row class="mt-2 mb-6">
          <v-col class="mr-3 container-style">
            <p class="section-title mb-0 text-center mt-2 mr-6">Alerts</p>
            <v-container>
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Warning</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Warning alert')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Alert -->
                  <WarningAlert :text="warningText" />
                  <!-- Alert -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <v-row justify="space-between" class="my-2">
          <!-- Badge Section -->
          <v-col cols="4" xl="6" class="mr-3 container-style">
            <p class="section-title mb-0 text-center">Badges</p>
            <v-container>
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Default</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Default Badge')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col class="d-flex justify-center">
                  <!-- Badge -->
                  <RegularBadge
                    text="Blue Badge"
                    color="lowOpacityBlue"
                    text-color="blueDark"
                  />
                  <!-- Badge -->
                </v-col>
              </v-row>

              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Deletable</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Deletable Badge')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col class="d-flex justify-center">
                  <!-- Badge -->
                  <RegularBadge
                    text="Red Badge"
                    color="lowOpacityRed"
                    text-color="berryDark"
                    :deletable="true"
                    @delete-badge="deleteBadge()"
                  />
                  <!-- Badge -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>

          <!-- Toast Buttons Section -->
          <v-col cols="7" xl="5" class="mr-3 container-style">
            <div class="d-flex justify-center">
              <p class="section-title mb-0 text-center mt-2 mr-2">Toasts</p>
              <v-btn
                icon
                color="caribbeanDark"
                @click="copyToClipboard('Toast')"
              >
                <v-icon>mdi-code-tags</v-icon>
              </v-btn>
            </div>
            <v-container>
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
              >
                <v-col class="d-flex flex-wrap justify-space-between">
                  <!-- Toast Buttons -->
                  <v-btn
                    v-for="(btn, idx) in toastButtons"
                    :key="idx"
                    class="main-action-btn ma-2"
                    @click="toastStyleHandler(btn, idx)"
                  >
                    {{ btn.label }}
                  </v-btn>
                  <!-- Toast Buttons -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';
import WarningAlert from '@/components/shared/customComponents/alertsBadges/warning-alert.vue';

export default {
  name: 'AlertsBadgesWiki',
  components: {
    RegularBadge,
    WarningAlert,
  },

  data: () => ({
    toastButtons: [
      { label: 'TOP LEFT', value: 'top_left' },
      { label: 'TOP RIGHT', value: 'top_right' },
      { label: 'BOTTOM LEFT', value: 'bottom_left' },
      { label: 'BOTTOM RIGHT', value: 'bottom_right' },
    ],
    warningText:
      'This is your warning text. If you continue something might blow up!',
  }),

  methods: {
    toastStyleHandler(btn, idx) {
      let toastParams = { position: {} };
      switch (btn.value) {
        case 'top_left':
          toastParams.timeout = 3000 + +idx;
          toastParams.messages = { error: `${btn.label} TOAST` };
          toastParams.position.top = true;
          toastParams.position.left = true;
          toastParams.type = 'error';
          toastParams.btnActionLabel = 'DONE';
          toastParams.btnActionShow = true;
          toastParams.actionBtnPress = this.toastClickHandler;
          toastParams.actionAfterClose = this.functionTest;
          break;
        case 'top_right':
          toastParams.timeout = 3000 + +idx;
          toastParams.messages = { info: `${btn.label} TOAST` };
          toastParams.position.top = true;
          toastParams.position.right = true;
          toastParams.type = 'info';
          break;
        case 'bottom_left':
          toastParams.timeout = 3000 + +idx;
          toastParams.messages = { warning: `${btn.label} TOAST` };
          toastParams.position.bottom = true;
          toastParams.position.left = true;
          toastParams.type = 'warning';
          break;
        case 'bottom_right':
          toastParams.timeout = 3000 + +idx;
          toastParams.messages = { success: `${btn.label} TOAST` };
          toastParams.position.bottom = true;
          toastParams.position.right = true;
          toastParams.type = 'success';
          toastParams.btnActionLabel = 'CANCEL';
          toastParams.btnActionShow = true;
          break;
      }
      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    toastClickHandler() {
      console.log('Toast Button Clicked');
    },

    selectedComponentHandler(name) {
      let selectedComponent = null;

      switch (name) {
        case 'Warning alert':
          selectedComponent = `<WarningAlert :text="warningText" />`;
          break;
        case 'Default Badge':
          selectedComponent = `<RegularBadge
              text="Blue Badge"
              color="lowOpacityBlue"
              text-color="blueDark"
            />`;
          break;
        case 'Deletable Badge':
          selectedComponent = `<RegularBadge
              text="Red Badge"
              color="lowOpacityRed"
              text-color="berryDark"
              :deletable="true"
              @delete-badge="deleteBadge()"
            />`;
          break;
        case 'Toast':
          selectedComponent = `triggerToast(name) {
            const toastParams = {
              position: { top: true, right: true },
              timeout: 3000,
              messages: {success: 'Great success!'},
              type: 'success',
            };

            this.$store.dispatch(
              'toastNotification/setupToastNotification',
              toastParams
            );
            this.$store.dispatch('toastNotification/showToastNotification', true);
          },`;
          break;
      }
      return selectedComponent;
    },

    triggerToast(name) {
      const toastParams = {
        position: { top: true, right: true },
        timeout: 3000,
        messages: { success: `${name} copied to clipboard` },
        type: 'success',
      };

      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    copyToClipboard(name) {
      let component = this.selectedComponentHandler(name);
      navigator.clipboard.writeText(component);
      this.triggerToast(name);
    },

    deleteBadge() {
      console.log('Badge Deleted');
    },
  },
};
</script>

<style scoped lang="scss">
.container-style {
  border: 1px dashed $grey-regular !important;
  border-radius: 5px;
}

.style-label {
  font-size: 13px !important;
}
</style>
